<template>
	<div class="main-content">
		<ktv-breadcrumb title="Price Info" page="Price Info" folder="CMS" />
		<b-button variant="success" @click="$bvModal.show('mAdd')">+ Add</b-button>		
		<Search
			:show-input-type="true" 
			:placeholder="'Search By ID & Name'"
			@change="onSearchFilter"
		/>
		
		<table class="table table-hover ">
			<thead class="thead">
				<tr class="">
					<th>Action</th>
					<th>No</th>
					<th>Commodity</th>
					<th>Price</th>
					<th>By Date</th>
					<th>Location</th>
					<th>Source</th>
				</tr>
			</thead>
			<tbody>
				<!-- <tr v-for="(a, key) in rows" :key="key"> -->
				<tr>
					<td>
						<b-dropdown id="dropdown-1" text="" variant="link" class="m-md-2">
							<b-dropdown-item @click="openForm(a.EntityID, act = 'view')"><i class="fa fa-eye" aria-hidden="true"></i> &nbsp; View</b-dropdown-item>
							<b-dropdown-item @click="openForm(a.EntityID, act = 'update')"><i class="fa fa-pencil" aria-hidden="true"></i> &nbsp; Update</b-dropdown-item>
							<b-dropdown-item @click="unRegister(a)"><i class="fa fa-trash-o" aria-hidden="true"></i> &nbsp; Delete</b-dropdown-item>
						</b-dropdown>
					</td>
					<td>1</td>
					<td>Coffee</td>
					<td>Rp. 225.000.000</td>
					<td>12/10/2022</td>
					<td>Indonesia, Lampung</td>
					<td>Local</td>
				</tr>
				<tr>
					<td>
						<b-dropdown id="dropdown-1" text="" variant="link" class="m-md-2">
							<b-dropdown-item @click="openForm(a.EntityID, act = 'view')"><i class="fa fa-eye" aria-hidden="true"></i> &nbsp; View</b-dropdown-item>
							<b-dropdown-item @click="openForm(a.EntityID, act = 'update')"><i class="fa fa-pencil" aria-hidden="true"></i> &nbsp; Update</b-dropdown-item>
							<b-dropdown-item @click="unRegister(a)"><i class="fa fa-trash-o" aria-hidden="true"></i> &nbsp; Delete</b-dropdown-item>
						</b-dropdown>
					</td>
					<td>2</td>
					<td>Paper</td>
					<td>Rp. 300.000.000</td>
					<td>12/10/2022</td>
					<td>Indonesia, Lampung</td>
					<td>Local</td>
				</tr>
				<tr>
					<td>
						<b-dropdown id="dropdown-1" text="" variant="link" class="m-md-2">
							<b-dropdown-item @click="openForm(a.EntityID, act = 'view')"><i class="fa fa-eye" aria-hidden="true"></i> &nbsp; View</b-dropdown-item>
							<b-dropdown-item @click="openForm(a.EntityID, act = 'update')"><i class="fa fa-pencil" aria-hidden="true"></i> &nbsp; Update</b-dropdown-item>
							<b-dropdown-item @click="unRegister(a)"><i class="fa fa-trash-o" aria-hidden="true"></i> &nbsp; Delete</b-dropdown-item>
						</b-dropdown>
					</td>
					<td>3</td>
					<td>Cacao</td>
					<td>Rp. 200.000.000</td>
					<td>12/10/2022</td>
					<td>Indonesia, Lampung</td>
					<td>Local</td>
				</tr>
			</tbody>
		</table>

		<paginationBarCustom 
			:state="paginationData" 
			@paginate="onPageChange()"
		/>
		<!-- MODAL ADD -->	
		<b-modal id="mAdd" title="Price Info Form" size="xl" hide-header-close no-close-on-esc no-close-on-backdrop hide-footer>
			<b-row>
				<b-col>
					<b-row>
						<b-col cols="9">
							<b-form-group id="fieldset-1" description="">
								<label for=""><b>Commodity</b></label>
								<v-select
									v-model="farmer"
									v-validate="'required'"
									:options="farmers"
									:loading="loading"
									laceholder="Enter Farmer ID or  Name to Search"
									:state="validateState('farmer')"
									name="farmer" @search="searchFarmers"
								/>
								<small class="text-danger">
									{{ veeErrors.first("farmer") }}
								</small>
							</b-form-group>
						</b-col>
						<b-col>
							<b-button class="mt-4" variant="success" @click="$bvModal.show('commodityList')">+ Add Commodity</b-button>
						</b-col>
					</b-row>
					
					

					<b-form-group id="fieldset-1" description="">
						<label for=""><b>Country</b></label>
						<v-select
							v-model="farmer"
							v-validate="'required'"
							:options="farmers"
							:loading="loading"
							laceholder="Enter Farmer ID or  Name to Search"
							:state="validateState('farmer')"
							name="farmer" @search="searchFarmers"
						/>
						<small class="text-danger">
							{{ veeErrors.first("farmer") }}
						</small>
					</b-form-group>

					<b-form-group id="fieldset-1" description="">
						<label for=""><b>Province</b></label>
						<v-select
							v-model="farmer"
							v-validate="'required'"
							:options="farmers"
							:loading="loading"
							laceholder="Enter Farmer ID or  Name to Search"
							:state="validateState('farmer')"
							name="farmer" @search="searchFarmers"
						/>
						<small class="text-danger">
							{{ veeErrors.first("farmer") }}
						</small>
					</b-form-group>

					<b-form-group id="fieldset-1" description="">
						<label for=""><b>District</b></label>
						<v-select
							v-model="farmer"
							v-validate="'required'"
							:options="farmers"
							:loading="loading"
							laceholder="Enter Farmer ID or  Name to Search"
							:state="validateState('farmer')"
							name="farmer" @search="searchFarmers"
						/>
						<small class="text-danger">
							{{ veeErrors.first("farmer") }}
						</small>
					</b-form-group>

					<b-form-group id="fieldset-1" description="">
						<label for=""><b>Sub District</b></label>
						<v-select
							v-model="farmer"
							v-validate="'required'"
							:options="farmers"
							:loading="loading"
							laceholder="Enter Farmer ID or  Name to Search"
							:state="validateState('farmer')"
							name="farmer" @search="searchFarmers"
						/>
						<small class="text-danger">
							{{ veeErrors.first("farmer") }}
						</small>
					</b-form-group>

					<b-form-group id="fieldset-1" description="" label="Collector">
						<b-form-input id="input-1" v-model="farmerid" class="mt-1"></b-form-input>
					</b-form-group>
				</b-col>

				<b-col>
					<b-form-group id="fieldset-1" description="" label="Price">
						<b-form-input id="input-1" v-model="farmerid" type="number" class="mt-1"></b-form-input>
					</b-form-group>

					<b-form-group id="fieldset-1" description="" label="Price Slot">
						<b-form-input id="input-1" v-model="farmerid" type="number" class="mt-1"></b-form-input>
					</b-form-group>

					<b-form-group id="fieldset-1" description="" label="Price Date">
						<b-form-input id="input-1" v-model="farmerid" type="date" class="mt-1"></b-form-input>
					</b-form-group>

					<b-form-group id="fieldset-1" description="" label="Source">
						<b-form-input id="input-1" v-model="farmerid" class="mt-1"></b-form-input>
					</b-form-group>

					<b-form-group label="Partner">
						<treeselect 
							v-model="partner" 
							v-validate="'required'" 
							:multiple="true" 
							:options="arrPartner" 
							name="province"
								
							:value-consists-of="valueConsistsOf"
							:state="validateState('province')"
						/>
						<small class="text-danger">
							{{ veeErrors.first("province") }}
						</small>
					</b-form-group>
				</b-col>
			</b-row>

			<div class="modal-footer">
				<ktv-button
					class="mt-3"
					text="Cancel"
					icon="close"
					color="light"
					@click="$bvModal.hide('mAdd', selected = ''), allFarmers(), clearModal()"
				/>

				<ktv-button
					class="mt-3"
					text="Register Account"
					icon="save"
					color="primary"
					@click="storeData()"
				/>\
			</div>
		</b-modal>

		<!-- MODAL LIST COMMODITY -->
		<b-modal id="commodityList" :title="`Commodity List`" size="lg" hide-header-close no-close-on-esc no-close-on-backdrop hide-footer>
			<b-row>
				<b-col>
					<b-button class="mt-4" variant="success" @click="$bvModal.show('addCommodity')">+ add Commodity</b-button>
					<br><br>
					<table class="table table-hover ">
						<thead class="thead">
							<tr class="">
								<th>Action</th>
								<th>No</th>
								<th>Date Created</th>
								<th>Commodity Name</th>
							</tr>
						</thead>
						<tbody>
							<!-- <tr v-for="(a, key) in rows" :key="key"> -->
							<tr>
								<td>
									<b-dropdown id="dropdown-1" text="" variant="link" class="m-md-2">
										<b-dropdown-item @click="openForm(a.EntityID, act = 'update')"><i class="fa fa-pencil" aria-hidden="true"></i> &nbsp; Update</b-dropdown-item>
										<b-dropdown-item @click="unRegister(a)"><i class="fa fa-trash-o" aria-hidden="true"></i> &nbsp; Delete</b-dropdown-item>
									</b-dropdown>
								</td>
								<td>1</td>
								<td>19/07/2022</td>
								<td>Cacao</td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>
			<br>



			<div class="modal-footer">
				<ktv-button
					class="mt-3"
					text="Close"
					icon="close"
					color="light"
					@click="closeModalCommodityList()"
				/>
			</div>
		</b-modal>

		<!-- MODAL ADD COMMODITY -->
		<b-modal id="addCommodity" :title="`Add New Commodity`" size="lg" hide-header-close no-close-on-esc no-close-on-backdrop hide-footer>
			<b-row>
				<b-col>
					<b-form-group id="fieldset-1" description="" label="Commodity Name">
						<b-form-input id="input-1" v-model="cmd" type="text" class="mt-1"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<br>



			<div class="modal-footer">
				<ktv-button
					class="mt-3"
					text="Cancel"
					icon="close"
					color="light"
					@click="closeModalAddCommodity()"
				/>

				<ktv-button
					class="mt-3"
					text="Save"
					icon="save"
					color="primary"
					@click="resetPassword()"
				/>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import {
		KtvButton,
	} from "@/components";
	import paginationBarCustom from "@/components/paginationBarCustom"
	import Search from "@/components/searchCustom"
	import Treeselect from '@riophae/vue-treeselect'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'

	export default {
		metaInfo: {
			title: "Price Info",
		},
		components: {
			KtvButton,
			paginationBarCustom,
			Search,
			Treeselect
		},
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */

				isLoading: false,
				modeTable: "remote",
				rows: [],
				totalRecords: 0,
				serverParams: {
					EntityID: "",
					PersonName: "",
					farmerid: "",
					sort: [
						{
							field: "",
							type: "ASC",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
				selected:"",
				showPass: false,
				item:"",
				password:"",
				repassword:"",

				farmers:[],
				farmer:null,
				paramFarmers: {
					EntityID: "",
					PersonName: "",
					farmerid: "",
					sort: [
						{
							field: "",
							type: "ASC",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
				EntityID:"",
				PersonName:"",
				farmerid:"",
				Email:"",
				PhoneNumber:"",
				commodity_name:"",
				
				paginationData: {
					pagination: {
						current_page: 1
					},
				},

			}
		},
		computed: {},
		watch: {
			farmer: {
				immediate: true,
				handler: function (value) {
					if (value) {
						this.findData()
					}
				}
			}

		},
		mounted() {
			// this.loadItems()
			// this.allFarmers()
		},
		methods: {
			resetFile() {
				this.$refs.filecsv.reset();
			},
			storeFile() {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}
					this.$swal.fire({
						title: 'Submiting...',
						allowOutsideClick: false,
						onBeforeOpen: () => {
							this.$swal.showLoading()
							let formData = new FormData()
							formData.append('data_csv', this.$refs.filecsv.files[0]);
							this.$http
								.Post(formData, this.$urlApi.farmer.registerBulk)
								.then(async (response) => {
									if(response.success === false){
										await this.$swal('Error!', response.error_message, 'error'); return
									}

									if(response.success === true){
										await this.$swal(
											'Saved!',
											'Successfully submit.',
											'success'
										);
										this.$bvModal.hide('mAdd')
									}

									this.loadItems();
								})
								.catch(() => {
									this.$swal({
										type: 'error',
										title: 'Upload Failed',
									})
								});
						}
					})
				})
			},
			clearModal(){
				this.farmer = null;
				this.farmerid = null;
				this.PersonName = null;
				this.EntityID = null
				this.PhoneNumber = null
				this.Email = null
				this.password = null
				this.commodity_name = null
			},
			storeData() {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}

					if(this.password !== this.repassword){
						this.$swal('The password does not match!', '', 'error')
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((response) => {
						if (response.value) {
							this.$swal.fire({
								title: 'Loading data...',
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
									let payload = {
										personname : this.PersonName,
										entitiyid : this.EntityID,
										phonenumber : '+' + this.PhoneNumber,
										email : this.Email,
										password : this.password,
									}
									this.$http.
										Post(payload, this.$urlApi.farmer.register)
										.then(async (r) => {
											if(r.success === true){
												await this.$swal('Success!', 'Successfully.', 'success')
												this.$bvModal.hide('mAdd')

											}
											if(r.success === false){
												await this.$swal('Error!', r.error_message, 'error'); return
											}

											await this.loadItems()
										})
										.catch(async (error) => {
											await this.$swal("Error!", error , "error")
										}).finally(() => this.$swal.close())
								}
							})
						}
					});
				});

			},
			findData() {
				let a = this.farmers.find(o => o.id === this.farmer.id);
				this.EntityID = a.objectData.EntityID;
				this.PersonName = a.objectData.PersonName;
				this.farmerid = a.objectData.farmerid;
				this.Email = a.objectData.Email;
				this.PhoneNumber = a.objectData.PhoneNumber;
				this.commodity_name = a.objectData.commodity_name;
			},
			uParams(value) {
				this.paramFarmers = Object.assign({}, this.paramFarmers, value)
			},
			searchFarmers(search, loading) {
				loading(true);
				this.uParams({
					EntityID: search,
					PersonName: search,
					farmerid: search,
				})
				this.allFarmers(loading)
			},
			allFarmers(loading) {
				this.$http
					.Post(this.paramFarmers, this.$urlApi.farmer.list)
					.then((r) => {
						this.farmers = [];
						r.results.data.forEach(async (value, index) => {
							await this.farmers.push({
								index: index,
								id: value.EntityID,
								farmerid: value.farmerid,
								label: value.farmerid + " - " + value.PersonName,
								objectData: value,
							});
						});
						loading(false);
					})
					.catch(() => {
						this.isLoading = false
						// this.$swal("Error!", error.response, "error")
					})
				this.updateParams({ currentPage: 1 })

			},
			// load items is what brings back the rows from server
			loadItems() {
				this.$swal.fire({
					title: 'Loading...',
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
						this.$http
							.Post(this.serverParams, this.$urlApi.farmer.list)
							.then((response) => {
								this.isLoading = false
								if (response.success) {
									this.rows = response.results.data
									this.paginationData.pagination = response.results
								} else {
									this.$swal("Error!", response.error_massage, "error")
								}
							})
							.catch((error) => {
								this.$swal("Error!", error.response, "error")
							}).finally(() => this.$swal.close())
					}
				})
				this.updateParams({ currentPage: 1 })
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange() {
				// this.updateParams({ currentPage: this.paginationData.pagination.current_page })
				// this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(value) {
				this.updateParams({
					EntityID: value.Name,
					PersonName: value.Name,
					farmerid: value.Name,					
				})
				this.loadItems()
			},
			openModal(){

			},
			openForm(id, act) {
				if(act === 'view'){
					this.$router.push(`/farmer/detailFarmer?id=${id}&act=view`)
				}

				if(act === 'update'){
					this.$router.push(`/farmer/detailFarmer?id=${id}&act=update`)
				}
			},
			unRegister(item) {
				this.item = item;
				this.$swal({
					title: 'Are you sure?',
					text: this.item.PersonName + "will be Unregister!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes'
				}).then((result) => {
					if (result.value) {
						this.$swal.fire({
							title: 'Unregister data...',
							allowOutsideClick: false,
							onBeforeOpen: () => {
								this.$swal.showLoading()
								let params = { PhoneNumber : this.item.PhoneNumber}
								this.$http.
									Post(params, this.$urlApi.farmer.unregister)
									.then(async (r) => {
										if(r.success === true){
											await this.$swal('Success!', 'Data has been Unregistered.', 'success'); return
										}
										if(r.success === false){
											await this.$swal('Error!', r.error_message, 'error'); return
										}

										await this.loadItems()
									})
									.catch(async (error) => {
										await this.$swal("Error!", error , "error")
									}).finally(() => this.$swal.close())
							}
						})
					}
				});
			},
			resetPassword() {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}
					if(this.password !== this.repassword){
						this.$swal('The password does not match!', '', 'error')
						return;
					}
					this.$swal({
						title: 'Are you sure?',
						text: "Reset to Default Password?",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes'
					}).then((r) => {
						if (r.value) {
							this.$swal.fire({
								title: 'Loading...',
								allowOutsideClick: false,
								onBeforeOpen: () => {
									this.$swal.showLoading()
									let params = {
										PhoneNumber : this.item.PhoneNumber,
										Password : this.password
									}
									this.$http.
										Post(params, this.$urlApi.farmer.resetPassword)
										.then(async (response) => {
											if(response.success === true){
												await this.$swal('Success!', 'Successfully Reset Password.', 'success');
												this.$bvModal.hide('mPassword')
											}

											if(response.success === false){
												await this.$swal('Error!', response.error_message, 'error'); return
											}

											await this.loadItems()
										})
										.catch(async (error) => {
											await this.$swal("Error!", error , "error")
										}).finally(() => this.$swal.close())
								}
							})
						}
					});

				});

			},
			closeModalCommodityList(){
				this.password = "";
				this.repassword = "";
				this.showPass = false;
				this.$bvModal.hide('commodityList')
			},
			closeModalAddCommodity(){
				this.password = "";
				this.repassword = "";
				this.showPass = false;
				this.$bvModal.hide('addCommodity')
			},
		}
	}
</script>

<style>
.dropdownitem-action:hover a,
.dropdownitem-action:focus a {
	background-color: #CAEAFF !important;
}
</style>